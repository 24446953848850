<template>
    <div>
        <Preloader v-if='isProcessing' />
        <h4 class="mb-4">Ученики на контроле</h4>

        <div class="widget">
            <div class="filter__container">
                <div class="row mb-3 justify-content-between">
                    <div class="input-group col-6">

                        <input
                           placeholder="Поиск..."
                           type="search"
                           class="form-control"
                           id="search-input"
                           @input="debounceSearch"
                           :value="searchWord"
                        >
                        <span class="input-group-append ml-3"></span>
                        <VCalendar
                            class='teacher-salaries-section-calendar'
                            mode='date'
                            is-range
                            :popover="{ visibility: 'focus' }"
                            :masks="{ input: ['DD.MM.YYYY']}"
                            @input='handleDateSelect'
                            v-model='date'
                        >
                            <template v-slot='{ togglePopover }'>
                                <div @click='togglePopover()'>
                                    <input
                                        class='form-control'
                                        :class="{'hidden': !date.start}"
                                        :value='`с ${formatDate(date.start)} по ${formatDate(date.end)}`'
                                        readonly
                                    />
                                </div>
                            </template>
                        </VCalendar>
                        <b-checkbox class="my-auto ml-3" @change="showDescribed">Только описанные</b-checkbox>
                        <b-checkbox class="my-auto ml-3" :checked="isShowWithoutNextLesson" @change="showWithoutNextLesson">Без назначенного урока</b-checkbox>
                    </div>
                </div>
                <div class="row mb-3 justify-content-between">
                  <div class="input-group col-5">
                      <a class="btn btn-success" :href="getLinkExcelDownload">Экспортировать в Excel</a>
                  </div>
                </div>

            </div>
            <pagination-counter v-if="pagination" class="text-right my-3" :meta="pagination"/>
            <table class="table table-hover" style="background: #ff000017;">
                <thead class="table_head">
                <tr>
                    <th class="text-center">ФИО ученика</th>
                    <th class="text-center">Следующий урок</th>
                    <th class="text-center">Уведомлен</th>
                    <th class="text-center">Заметка</th>
                    <th class="text-center">Описан</th>
                    <th class="text-center">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="student in students" :key="student.id">
                    <td class="text-center">{{ student.full_name }}</td>
                    <td class="text-center">{{ student.next_lesson }}</td>
                    <td class="text-center">{{ student.notified_at}}</td>
                    <td class="note-column">
                        <v-clamp autoresize :max-lines="3">{{ student.note }}</v-clamp>
                    </td>
                    <td class="text-center">{{ student.described_at }}</td>
                    <td class="actions_container">
                        <div class="column-control">
                            <a class="btn mr-2 btn-primary" @click.prevent="describedDebtor(student.id)"
                               title="Описать должника">
                                <span class="fa fa-address-card"></span>
                            </a>
                            <router-link class="leads_table_action_link btn mr-2 btn-primary" title="Перейти на страницу ученика"
                                         :to="{name: 'StudentDetail', params: {id: student.student_id}}">
                                <span class="fa fa-user-circle"/>
                            </router-link>
                            <a class="btn mr-2 btn-primary" @click.prevent="callModalEditNote(student.id, student.full_name, student.note)"
                               title="Добавить/Редактировать заметку">
                                <span class="fa fa-sticky-note delete"></span>
                            </a>
                            <a class="btn mr-2 btn-warning" @click.prevent="graduatedDebtor(student.id)"
                               title="Завершить обучение">
                                <span class="fa fa-user-times delete"></span>
                            </a>
                            <a v-if="isAdmin" class="btn mr-2 btn-danger" @click.prevent="deleteDebtor(student.id)"
                               title="Удалить должника">
                                <span class="fa fa-trash-o delete"></span>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div v-if='pagination && pagination.last_page !== 1' class='py-1 overflow-auto d-flex justify-content-between align-items-center'>
                <b-pagination
                    class='paginator my-0'
                    :total-rows='pagination.total'
                    :per-page='pagination.per_page'
                    @page-click='handlePageSelect'
                    v-model="page"
                />
                <pagination-counter class="mr-4" :meta="pagination"/>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {debounce} from "debounce";
import VCalendar from "v-calendar/lib/components/date-picker.umd";
import DebtorsModalNoteEdit from "@/components/admin/debtors/DebtorsModalNoteEdit.vue";
import Preloader from "@/components/Preloader.vue";
import VClamp from 'vue-clamp'
import {mapGetters} from "vuex";
import PaginationCounter from "@/components/Pagination/PaginationCounter.vue";


export default {
    name: 'Debtor',
    components: {PaginationCounter, VCalendar, Preloader, VClamp},
    data() {
        return {
            baseUrl: process.env.VUE_APP_API_BASE_URL,
            searchWord: '',
            students: [],
            page: 1,
            isAdmin: false,
            onlyDescribed: false,
            isShowWithoutNextLesson: false,
            isProcessing: false,
            date: {
                start: dayjs().set('date', 1).format('YYYY-MM-DD'),
                end: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            pagination: null,
        }
    },
    async beforeRouteUpdate(to, from, next) {
        this.loader = true;
        await this.loadStudents(to.query);
        this.loader = false;
        next();
    },

  async created() {
        this.isAdmin = this.getRole === 'admin'
        if (this.$route.query.search) {
            this.searchWord = this.$route.query.search;
        }

        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }


        const routeDateFrom = this.$route.query?.date_from;
        const routeDateTo = this.$route.query?.date_to;

        if (routeDateFrom || routeDateTo) {
            dayjs.extend(customParseFormat);
            if (routeDateFrom) this.date.start = dayjs(routeDateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
            if (routeDateTo) this.date.end = dayjs(routeDateTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }

        this.onloadQuerySelect()

        // await this.loadStudents(this.$route.query);
    },
    computed: {
      ...mapGetters({
          getRole: 'user/getRole',
      }),
      getLinkExcelDownload() {
        let queries = '';

        for (const [key, value] of Object.entries({ ...this.$route.query })) {
          if (value !== null) {
            if (queries === '') {
              queries += `?${key}=${value}`
              continue
            }
            queries += `&${key}=${value}`
          }
        }

        return `${this.baseUrl}/crm/debtors/export-excel${queries}`
      },
    },

    methods: {
        async handlePageSelect(bvEvent, page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, { page });
                await this.$router.push({ query: query });
            }

            return false;
        },
        callModalEditNote(debtorId, debtorName, debtorNote) {
            this.$modal.show(DebtorsModalNoteEdit, {
                form: {
                    debtor_id: debtorId,
                    debtor_name: debtorName,
                    debtor_note: debtorNote,
                },
            }, {
                name: 'DebtorsModalNoteEditContainer',
                width: '992px',
                height: 'auto',
                scrollable: true,
            }, {
                'before-close': (event) => this.fetchDataAfterModalSubmit(event),
            });
        },

        async fetchDataAfterModalSubmit(event) {
            if (event.params.refetch) await this.loadStudents(this.$route.query);
        },

        debounceSearch: debounce(async function(e) {
            const val = e.target.value;
            this.searchWord = val;
            let query
            if (this.searchWord) {
              query = Object.assign({}, this.$route.query, {search: this.searchWord, page: 1});
            } else {
              query = Object.assign({}, this.$route.query);
              delete query.search;
            }
            this.page = 1
            await this.$router.push({query});
        }, 600),

        async deleteDebtor(id) {
            if (confirm('Удалить должника?')) {
                this.isProcessing = true;
                await this.$axios.delete(`/crm/debtors/${id}/remove`);
                await this.loadStudents(this.$route.query)
            }
        },
        async describedDebtor(id) {
            if (confirm('Описать должника?')) {
                this.isProcessing = true;
                await this.$axios.post(`/crm/debtors/${id}/describe`);
                await this.loadStudents(this.$route.query)
            }
        },
        async graduatedDebtor(id) {
            if (confirm('Завершить обучение?')) {
                this.isProcessing = true;
                await this.$axios.delete(`/crm/debtors/${id}/graduated`);
                await this.loadStudents(this.$route.query)
            }
        },
        async loadStudents(query) {
            this.isProcessing = true;

            let response = await this.$axios.get(`/crm/debtors`, {
                params: {
                    ...query
                }
            });
            this.students = response.data.data;
            this.pagination = response.data.meta;

            this.isProcessing = false;
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        async showDescribed() {
            this.onlyDescribed = !this.onlyDescribed
            let query
            if (this.onlyDescribed) {
              query = Object.assign({}, this.$route.query, {only_described: this.onlyDescribed, page: 1});
            } else {
              query = Object.assign({}, this.$route.query);
              delete query.only_described;
            }
            this.page = 1
            await this.$router.push({query});
        },
        async showWithoutNextLesson() {
            this.isShowWithoutNextLesson = !this.isShowWithoutNextLesson
            let query
            if (this.isShowWithoutNextLesson) {
              query = Object.assign({}, this.$route.query, {without_next_lesson: this.isShowWithoutNextLesson, page: 1});
              delete query.date_from;
              delete query.date_to;
            } else {
              query = Object.assign({}, this.$route.query, {
                date_from: dayjs(this.date.start).format('DD.MM.YYYY'),
                date_to: dayjs(this.date.end).format('DD.MM.YYYY')
              });

              delete query.without_next_lesson;
            }
            this.page = 1
            await this.$router.push({query});
          },
        onloadQuerySelect() {
            const query = this.$router.push({
                query: {
                    search: this.searchWord,
                    date_from: dayjs(this.date.start).format('DD.MM.YYYY'),
                    date_to: dayjs(this.date.end).format('DD.MM.YYYY'),
                    page: this.page,
                }
            })
            this.$emit('on-date-select', query);
        },
        handleDateSelect() {
          let query = Object.assign({}, this.$route.query, {
            date_from: dayjs(this.date.start).format('DD.MM.YYYY'),
            date_to: dayjs(this.date.end).format('DD.MM.YYYY'),
            page: 1
          })
          if (this.isShowWithoutNextLesson) {
            delete query.without_next_lesson
            this.showWithoutNextLesson()
          }

          this.page = 1
          this.$router.push({query});

          this.$emit('on-date-select', query);
        },

    },
}
</script>

<style lang="scss" scoped>
.btn .glyphicon {
  top: 0 !important;
}

.column-control {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
}

.button_add {
  display: inline-block;
  vertical-align: middle;
  border: none;
  font-weight: 400;
  background: #3E86CA;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0 20px;
  font-size: 14px;
  height: 100%;
  transition: .1s;

  &:hover {
    background: #2e6ca7;
    text-decoration: none;
  }
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  margin-right: 30px;
  margin-left: 30px;
  visibility: hidden;
  /*top: -3px;*/
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}

.note-column {
    max-width: 400px;
}
</style>