import { render, staticRenderFns } from "./Debtors.vue?vue&type=template&id=5ca13308&scoped=true"
import script from "./Debtors.vue?vue&type=script&lang=js"
export * from "./Debtors.vue?vue&type=script&lang=js"
import style0 from "./Debtors.vue?vue&type=style&index=0&id=5ca13308&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca13308",
  null
  
)

export default component.exports