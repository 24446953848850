<template>
    <div class='teacher-salary-modal bg-gray-100'>
        <Preloader v-if='loading' class='overlay-small' />

        <h3 class='d-block p-4 mb-4 border-bottom border-gray'>
            <template v-if='formData.debtor_name'>Ученик: {{ formData.debtor_name }}</template>
        </h3>

        <div class="px-4">
            <b-form-textarea
                id="textarea-rows"
                placeholder="Заметка"
                rows="8"
                v-model="formData.debtor_note"
            ></b-form-textarea>
        </div>

        <b-form class='p-4' @submit.prevent='handleSubmit'>
            <div class='d-flex w-100 justify-content-end align-center mt-6'>
                <b-button type='button' variant='danger' class='mr-4' @click='closeModal'>Отменить</b-button>
                <b-button
                    type='submit'
                    variant='success'
                >
                    Сохранить
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import dayjs from "dayjs";

export default {
    name: 'DebtorsModalNoteEdit',
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    components: {
        Preloader,
    },
    data() {
        return {
            loading: false,
            formData: {},
            modifiedNote: '',
        };
    },
    async created() {
        for (const [k, v] of Object.entries(this.form)) {
            this.formData[k] = v;
        }

        await this.dataFetching();
    },
    computed: {

    },
    methods: {
        async dataFetching() {
            this.loading = true;

            this.loading = false;
        },
        closeModal() {
            this.$modal.hideAll();
        },
        async handleSubmit() {
            this.loading = true;

            try {
                await this.$axios.post(`crm/debtors/${this.formData.debtor_id}/add-note`, {
                    note : this.formData.debtor_note,
                });

                this.loading = false;

                this.$toasted.success('Заметка сохранена', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
                this.$emit('close', { refetch: true });
            } catch (ex) {
                this.loading = false;
                console.log('Cant save debtor note info: ', ex);
                let errMsg = 'Ошибка сохранения заметки';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
            //
            // this.closeModal()
        }
    },
};
</script>

<style lang='scss' scoped>
.is-invalid {
    border-color: #FD5F00 !important;
}

.bg-gray-100 {
    background-color: #f9fbfd;
}

.teacher-salary-modal-content {
    max-height: 440px;
    padding: 8px 20px 16px;
    overflow: hidden;
}

.tsmp-scrollbar {
    &::-webkit-scrollbar-track {
        border-radius: 20px;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: rgba(#1B3C66, 0.2);
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        height: 58px;
        background-color: #498BDF;
        border-radius: 20px;
    }
}

.tsmp-students-table-scrollbar {
    position: relative;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    max-height: 325px;
    overflow-y: auto;
}

.tsmp-students-header,
.tsmp-students-body-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 52px;
    align-items: center;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.penalties {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(5, 1fr);

        & > * {
            padding: 12px 20px;
        }
    }
}

.payments {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(3, 1fr) 108px;

        & > * {
            padding: 12px 20px;
        }
    }
}

.tsmp-students-header {
    margin-left: -20px;
    margin-right: -20px;
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    background-color: rgba(#798892, 0.05);

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-body-row-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    min-height: 58px;
}

.tsmp-collapse {
    cursor: default;

    thead {
        font-size: 12px;
    }
}

.tsmp-comment {
    width: 220px;
}

.tab-content > .tab-pane {
    padding: 0 20px;
}

.total-example {
    padding-top: 5px;
    color: #6B86A3;

    .text {
        font-size: 11px;
    }
}
</style>